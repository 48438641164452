export const PENDING = "PENDING";
export const CLOSED = "CLOSED";
export const COMPLETED = "COMPLETED";
export const AT_COUNSELLOR = "AT_COUNSELLOR";
export const AT_DOCTOR = "AT_DOCTOR";
export const AT_PHARMACY = "AT_PHARMACY";
export const CANCELLED = "CANCELLED";

export const ACTIVE = "ACTIVE";
export const INACTIVE = "INACTIVE";

export const DAMS_DEFAULT_NO = "NO";

export const DOCTOR = "DOCTOR";

export const SERVICE_TYPE_REPORT = 'SERVICE_TYPE_REPORT';
export const PATIENT_GENDER_REPORT = "PATIENT_GENDER_REPORT"
export const STATIC_REPORTS = {
    MONTHLY_REPORT: "MONTHLY_REPORT",
    ANNEXURE_B: "ANNEXURE_B",
    ANNEXURE_E: "ANNEXURE_E",
    ANNEXURE_F: "ANNEXURE_F",
}

export const PAGES = {
    DASHBOARD: "/dashboard",
    PATIENTS: "/patients",
    PATIENT_VISIT: "/patient-visit",
    USERS: "/users",
    MEDICINES: "/medicines",
    PURCHASE_MEDICINES: "/purchase-medicines",
    SALE_RETURN: "/sale-return",
    REPORTS: "/reports",
    CHANGE_PASSWORD: "/change-password",
    HELP: "/help",
    DEPARTMENTS: "/departments",
    SUPPLIERS: "/suppliers",
    HOSPITALS: "/hospitals",
    SERVICES: "/services",
    MEDS: "/meds",
    MEDICINE_TYPES: "/medicine-type",
    SERVICE_TYPES: "/service-type",
    EXPORT: "/export",
    ATTENDANCE: "/attendance",
    EXPENSE_CATEGORY: "/expense-category",
    EXPENSE: "/expense"
}

export const ROLES = {
    SADMIN: "SADMIN",
    HOSPITAL_ADMIN: "HOSPITAL_ADMIN",
    ADMIN: "ADMIN",
    DOCTOR: "DOCTOR",
    DOCTOR_ASSISTANT: "DOCTOR_ASSISTANT",
    PHARMACIST: "PHARMACIST",
    COUNSELLOR: "COUNSELLOR",
    RECEPTION: "RECEPTION",
    MANAGER: "MANAGER",
}

export const MODULES = {
    DASHBOARD_MODULE: "dashboard",
    DAMS_MODULE: "dams",
    MEDICINES_MODULE: "medicines",
    SERVICIES_MODULE: "services",
    REPORTS_MODULE: "reports",
    ATTENDANCE_MODULE: "attendance",
    VIEW_PRECRIPTION: "prescription",
    VIEW_INVESTIGATION: "investigation",
    VIEW_MEDICINE_PURCHASE_RETURN: "medicine_purchase_return"
}

export const getOptionsForConfigDropdowns = {
    "invoice_template": [{ label: "Template 1", value: 'INV_1' }],
    "prescription_template": [{ label: "Template 1", value: 'PRE_1' }, { label: "Template 2", value: 'PRE_2' }],
    "patient_search_character_length": [{ label: "3 Characters", value: '3' }, { label: "4 Characters", value: '4' }],
}

export const CONFIGS = {
    AADHAR_NO: "aadhar_no",
    MOBILE_NO: "mobile_no",
    ONLINE_ID: "online_id",
    PNDT: "pndt",
    FILE_NO: "file_no",
    SPOUSE: "spouse",
    FATHER_NAME: "father_name",
    CITY: "city",
    ADDRESS: "address",
    DEPARTMENT: "department",
    INVESTIGATION: "investigation",
    PRESCRIPTION_FOLLOWUP: "prescription_followup",
    PRESCRIPTION_DOSE_DROPDOWN: "prescription_dose_dropdown",
    PRESCRIPTION: "prescription",
    MEDICINE_PURCHASE_RETURN: "medicine_purchase_return",
    AT_PHARAMACY: "input_pharmacy_name"
}

export const hospitalConfigs = [
    {
        "key": "aadhar_no",
        "name": "Aadhar Number",
        "show": "false",
        "mandatory": "false",
        "configType": "switch",
    },
    {
        "key": "mobile_no",
        "name": "Mobile Number",
        "show": "false",
        "mandatory": "false",
        "configType": "switch",
    },
    {
        "key": "online_id",
        "name": "Online Id",
        "show": "false",
        "mandatory": "false",
        "configType": "switch",
    },
    {
        "key": "file_no",
        "name": "File Number",
        "show": "false",
        "mandatory": "false",
        "configType": "switch",
    },
    {
        "key": "pndt",
        "name": "PNDT Form Number",
        "show": "false",
        "mandatory": "false",
        "configType": "switch",
    },
    {
        "key": "spouse",
        "name": "Spouse",
        "show": "false",
        "mandatory": "false",
        "configType": "switch",
    },
    {
        "key": "father_name",
        "name": "Father's Name",
        "show": "false",
        "mandatory": "false",
        "configType": "switch",
    },
    {
        "key": "city",
        "name": "City",
        "show": "false",
        "mandatory": "false",
        "configType": "switch",
    },
    {
        "key": "address",
        "name": "Address",
        "show": "false",
        "mandatory": "false",
        "configType": "switch",
    },
    {
        "key": "department",
        "name": "Department",
        "show": "false",
        "mandatory": "false",
        "configType": "switch",
    },
    // {
    //     "key": "investigation",
    //     "name": "Investigation",
    //     "show": "false",
    //     "mandatory": "false",
    //     "configType": "switch",
    // },
    // {
    //     "key": "prescription",
    //     "name": "Prescription",
    //     "show": "false",
    //     "mandatory": "false",
    //     "configType": "switch",
    // },
    {
        "key": "prescription_followup",
        "name": "Prescription Follow-up",
        "show": "false",
        "mandatory": "false",
        "configType": "switch",
    },
    {
        "key": "prescription_dose_dropdown",
        "name": "Prescription Freq/Dose Dropdown",
        "show": "false",
        "mandatory": "false",
        "configType": "switch",
    },
    {
        "key": "auto_complete_print",
        "name": "Auto-Completed on Print",
        "show": "false",
        "mandatory": "false",
        "configType": "switch",
    },


    {
        "key": "input_pharmacy_name",
        "name": "At Pharmacy ",
        "value": "AT_PHARMACY",
        "configType": "input_pharmacy_name",
    },
    {
        "key": "no_of_days",
        "name": "No. of Days for Investigation",
        "value": 1,
        "configType": "number_input_x_days",
    },
    {
        "key": "patient_search_character_length",
        "name": "No. of Chars to Auto-Search Patient",
        "value": getOptionsForConfigDropdowns['patient_search_character_length'][0].value,
        "configType": "dropdown",
    },
    {
        "key": "invoice_template",
        "name": "Invoice Template",
        "value": getOptionsForConfigDropdowns['invoice_template'][0].value,
        "configType": "dropdown",
    },
    {
        "key": "prescription_template",
        "name": "Prescription Template",
        "value": getOptionsForConfigDropdowns['prescription_template'][0].value,
        "configType": "dropdown",
    },
];